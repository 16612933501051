.title{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    color:black;
    margin: 25px;
}
p{
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
    height: fit-content;
    margin-block-start: 0;
    margin-block-end: 0;
    /* font-size: 16pt; */
}
p:hover{
    cursor: pointer;
}