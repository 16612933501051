.nav-ul{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60vw;
    margin: 0 auto;
}

.nav-elem{
    padding: 3px 10px 3px 10px;
    height: 27px;
    display: flex;
    justify-content:space-around;
    align-items: center;
    border-radius: 1px;
    border:dashed 1px var(--tertiary);
    border-right: none;
    border-left: none; 
}

.nav-elem:hover{
    background-color: var(--secondary);
    text-decoration: underline;
    font-weight: bold;
    font-size:calc(100% * 0.94);
}


.nav-elem > *{
    margin: 0;
}
.nav-elem img{
    padding: 0px 5px 0  0;
    height:15px; 
}

@media only screen and (max-width: 768px) {
    .nav-ul{
        width: 90vw;
        flex-direction: column;
        
    }
    .nav-elem{
        padding: 3px 10px 3px 10px;
        height: 27px;
        display: flex;
        justify-content:center;
        align-items: center;
        border-radius: 1px;
        border-top:none;
        border-bottom:dashed 1px var(--tertiary);
        border-right:none;
        border-left:none;
        margin-bottom: 5px;
    }
    .nav-elem img{
        padding: 0px 2px 0  0; 
    }
}