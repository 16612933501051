

.external-link{
    color: blue;
}

h1{
    margin: 0;
    font-family: "Playfair Display";
}


.flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60vw;
}

#demo_reel_video{
    width: 100%;
    height: 100vh;
    border: 0;
    margin: 10px;
}

.github-readme-md {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60vw;
}

.markdown-body{
    display: flex;
    justify-content: center;
    align-items: center;
}