.entry{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h1{
    margin: 0;
    font-family: "Playfair Display";
}
.bandcamp-embed{
    width: fit-content;
    height: 120px;
    border: 0;
    margin: 10px;
}

.soundcloud-embed{
    height: 400px;
    border: 0;
    width: stretch;
}

.lyrics{
    font-style:italic;
    font-weight: bold;
}

.embeds{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;    
}
.embed{
    width: stretch;
    height:inherit;
}

@media only screen and (max-width: 768px) {
    .embeds{
        flex-direction: column;
        align-items: center;
    }
    .embed{
        height:400px;
    }
}

