.content-window {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 100vw;
}

.content-grid{
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    width: 60vw;
    justify-content: space-between;
    align-items: space-around;
}

@media only screen and (max-width: 768px) {
    .content-window{
        width: 100vw;
    }
    .content-grid{
        justify-content: center;
    }

}