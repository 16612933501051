.dir-list{
    display: flex;
    flex-direction: column;
    width: 60vw;
    height: 100px;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    border-radius: 1px;
    border:dashed 1px var(--tertiary);
    box-sizing: border-box;
    margin: 30px;
    border-right: none;
    border-left: none;    
}
.dir-list::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

@media only screen and (max-width: 768px) {
    .dir-list{
        width: 90vw;
        border-right: none;
        border-left: none;
        padding: 0px;
    }
  }